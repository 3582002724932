// import {confirmNotice} from
//   "packs/controllers/shared/common/notices/flash_alerts_controller";

// let deferredPrompt;

/**
 * Listen for the the state change indicating that the service_worker
 * is installed and or activated.
 * @param {Object} worker - the service_worker
 * @private No response value
 */
function _trackInstalling(worker) {
  worker.addEventListener("statechange", function() {
    if (worker.state == "installed") {
      // A new service worker is available, inform the user
      _updateReady(worker);
    } else if (worker.state == "activated") {
      window.location.reload();
    }
  });
}

/**
 * Display the user a "Yes/No" Dialog inviting the user to update
 * the locally cached/installed PWA service_worker
 * @param {Object} worker - the service_worker
 * @private No response value
 */
function _updateReady(worker) {
  // const msg = "New version available for download";

  if (worker.state != "redundant") {
    worker.postMessage({action: "skipWaiting"});
  } else {
    window.location.reload();
  }

  // confirmNotice.info(msg, function() {
  //   console.log("[Companion]", "Confirm Notification accepted!");
  //   if (worker.state != "redundant") {
  //     worker.postMessage({action: "skipWaiting"});
  //   } else {
  //     window.location.reload();
  //   }
  // });
};

//
// New method for showing the "GREEN" alert message
//
// function _showInstallPromotion() {
//   const msg = "Install Acima Mobile?";
//   console.log("msg == " + msg);
//
//   confirmNotice.success(msg, function() {
//     // Show the install prompt
//     deferredPrompt.prompt();
//
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === "accepted") {
//         console.log("User accepted the install prompt");
//       } else {
//         console.log("User dismissed the install prompt");
//       }
//       // clear out deferredPrompt value
//       deferredPrompt = null;
//     });
//   });
// };

if (navigator.serviceWorker) {
  // change the value of cache-breaker=1 to trigger service worker update.
  navigator.serviceWorker.register("/service-worker.js?cache-breaker=3").then(function(reg) {
    if (!navigator.serviceWorker.controller) {
      // The window client isn't currently controlled so it's a new service
      // worker that will activate immediately
      return;
    }

    // //////////////////////////////////////
    // The three major listeners:  installing, waiting, updatefound

    // on new serviceworker
    if (reg.waiting) {
      _updateReady(reg.waiting);
      return;
    }

    if (reg.installing) {
      _trackInstalling(reg.installing);
      return;
    }

    // We are currently controlled so a new SW may be found...
    // Add a listener in case a new SW is found,
    reg.addEventListener("updatefound", function() {
      _trackInstalling(reg.installing);
    });

    reg.addEventListener("statechange", function(e) {
      if (e.target.state === "installed") {
        console.log("statechange: installed");
      } else if (e.target.state === "activated") {
        console.log("statechange: activated");
        window.location.reload();
      }
    });

    // End the three major listeners
    // //////////////////////////////////////

    console.log("[Companion]", "Service worker registered!", reg.scope);
  });

  // listen for the controlling service worker changing and reload the page
  navigator.serviceWorker.addEventListener("controllerchange", function() {
    window.location.reload();
  });

  // message from the user post skipWaiting update, so reload the page
  navigator.serviceWorker.addEventListener("message", function() {
    window.location.reload();
  });

  // //////////////////////////////////////
  // install prompt

  // window.addEventListener('beforeinstallprompt', (e) => {
  //   // Prevent the mini-infobar from appearing on mobile
  //   e.preventDefault();
  //   // Stash the event so it can be triggered later.
  //   deferredPrompt = e;
  //   // Update UI notify the user they can install the PWA
  //   _showInstallPromotion();
  // });

  // window.addEventListener('DOMContentLoaded', () => {
  //   let displayMode = 'browser tab';
  //   if (navigator.standalone) {
  //     displayMode = 'standalone-ios';
  //   }
  //   if (window.matchMedia('(display-mode: standalone)').matches) {
  //     displayMode = 'standalone';
  //   }
  //   // Log launch display mode to analytics
  //   console.log('DISPLAY_MODE_LAUNCH:', displayMode);
  // });

  // App successfully installed
  window.addEventListener("appinstalled", (event) => {
    console.log("👍", "appinstalled", event);
  });
}
